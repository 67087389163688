import React from 'react'
import SEO from '../../components/common/seo'
import UsersView from '../../components/Admin/Users'

const Users = () => (
  <>
    <SEO title="Users" />
    <UsersView />
  </>
)

export default Users
