import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  Skeleton,
  TableFooter
} from '@mui/material'

import Header from '../../common/Header'
import { routePaths } from '../../../constants/routes'
import { User } from '../../../helpers/types'
import { useProfileContext } from '../../../contexts/profileContext'
import { Groups, Home, Settings } from '@mui/icons-material'
import { SubContainer } from '../../common/Styled'
import { fetchAdminUsers } from '../../../helpers/api/order'

const title = 'Utilisateurs'
const items = [
  {
    icon: <Home />,
    label: 'Accueil',
    route: routePaths.home
  },
  {
    icon: <Settings />,
    label: 'Admin',
    route: routePaths.admin.index
  },
  {
    icon: <Groups />,
    label: title
  }
]

const Users = () => {
  const profile = useProfileContext()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<User[]>([])
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const isAdmin = profile?.isAdmin

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const result = await fetchAdminUsers()
      setUsers(result)
      setLoading(false)
    }
    if (isAdmin) fetchUsers()
  }, [isAdmin])

  if (!isAdmin) return null

  return (
    <SubContainer>
      <Header {...{ items, title }} />
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Date de création</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              new Array(3).fill('').map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            {!loading &&
              users.length > 0 &&
              users.map(({ id, firstName, lastName, email, createdAt }) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    {id}
                  </TableCell>
                  <TableCell>{firstName}</TableCell>
                  <TableCell>{lastName}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>
                    {moment.utc(createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            {!loading && users.length === 0 && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  colSpan={5}
                >
                  Il n&#39;y a pas d&#39;utilisateurs
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              count={users?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </SubContainer>
  )
}

export default Users
